import { useEffect, useState } from 'react'

import { state } from '../store/state'

import routes from './routes'
import { componentIdFromRouteId, match } from './matcher'
import { EMPTY_ROUTES, router } from './router'

export default function Router(props) {
  const [child, setChild] = useState({ componentId: 'root', component: null })

  useEffect(() => {
    function handleHashChange() {
      if (!window) return

      const location = window.location.hash

      if (EMPTY_ROUTES.includes(location) || state.currentHash.get() !== location) {
        router(state).navigate(location)
      }

      setChild((last) => {
        const { componentId, component } = match(location, props)

        if (last?.componentId === componentId) return last

        state.uiComponentId.set(componentId)

        return {
          componentId,
          component,
        }
      })
    }

    window.addEventListener('hashchange', handleHashChange)

    handleHashChange()

    return () => {
      window.removeEventListener('hashchange', handleHashChange)
    }
  }, [])

  const renderedRouteIds: string[] = []

  return (
    <>
      {routes
        .filter((r) => r.preserve)
        .map((r) => {
          const routeId = componentIdFromRouteId(r.id)
          const shouldDisplay = child?.componentId === routeId
          if (renderedRouteIds.includes(routeId)) return null
          renderedRouteIds.push(routeId)
          return (
            <div
              key={routeId}
              className={`router ${routeId} h-full w-full min-h-full max-h-full bg-[var(--secondary)]`}
              style={{ display: shouldDisplay ? 'block' : 'none' }}
            >
              {props[routeId]}
            </div>
          )
        })}

      <div className={'not-in-routes bg-[var(--background)]'}> {child?.component}</div>
    </>
  )
}
